import React from "react"
import bopss from "../../assets/images/bopss.webp"
import esoprs from "../../assets/images/esoprs.webp"
import aao from "../../assets/images/aao.webp"
import imperialCollege from "../../assets/images/imperial-college.webp"
import royalCollege from "../../assets/images/royal-college.webp"
import iranianMedical from "../../assets/images/iranian-medical.webp"
import aesthetics from "../../assets/images/aesthetics.webp"
import independentDoctors from "../../assets/images/independent-doctors.webp"
import pembrokeCollege from "../../assets/images/pembroke-college.webp"
import cmac from "../../assets/images/cmac-logo.webp"
import containerClasses from "../../styles/container.module.css"

export const AffiliateList = () => (
  <div className={containerClasses.container}>
    <div className="flex flex-col items-center justify-center col-span-4 py-8 md:py-12 lg:py-2 lg:flex-row md:col-span-8 lg:justify-between lg:col-span-12 xl:justify-between">
      <a
        className="flex justify-center w-full lg:w-unset"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.bopss.co.uk/"
      >
        <img
          loading="lazy"
          className="h-6 mb-4 lg:mb-0 lg:mr-5"
          alt="British Oculoplastic Surgey Society"
          src={bopss}
        />
      </a>
      <a
        className="flex justify-center w-full lg:w-unset"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.esoprs.eu/"
      >
        <img
          loading="lazy"
          width="32"
          height="32"
          className="h-6 mb-4 lg:mb-0 lg:mr-5"
          alt="ESOPRS"
          src={esoprs}
        />
      </a>
      <a
        className="flex justify-center inline lg:w-unset"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.aao.org/"
      >
        <img
          loading="lazy"
          width="103"
          height="32"
          className="w-20 h-6 mb-4 lg:mb-0 lg:mr-5"
          alt="American Academy of Ophthalmology"
          src={aao}
        />
      </a>
      <a
        className="flex justify-center w-full lg:w-unset"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.imperial.ac.uk/"
      >
        <img
          loading="lazy"
          width="128"
          height="24"
          className="w-24 h-6 mb-4 lg:mb-0 lg:mr-5"
          alt="Imperial College London"
          src={imperialCollege}
        />
      </a>
      <a
        className="flex justify-center w-full lg:w-unset"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.rcophth.ac.uk/about/rcophth/rcophth-honorary-fellowships/#:~:text=Ordinances%20of%20The%20Royal%20College,a%20significant%20contribution%20to%20ophthalmology/"
      >
        <img
          loading="lazy"
          width="42"
          height="32"
          className="w-6 h-6 mb-4 lg:mb-0 lg:mr-5"
          alt="The Royal College of Ophthalmologists"
          src={royalCollege}
        />
      </a>
      <a
        className="flex justify-center w-full lg:w-unset"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.iranianmedicalsociety.net/"
      >
        <img
          loading="lazy"
          width="36"
          height="32"
          className="w-6 h-6 mb-4 lg:mb-0 lg:mr-5"
          alt="Iranian Medical Society"
          src={iranianMedical}
        />
      </a>
      <a
        className="flex justify-center w-full lg:w-unset"
        target="_blank"
        rel="noopener noreferrer"
        href="https://aestheticsjournal.com/"
      >
        <img
          loading="lazy"
          width="96"
          height="24"
          className="w-20 h-5 mb-4 lg:mb-0 lg:mr-5"
          alt="Aesthetics"
          src={aesthetics}
        />
      </a>
      <a
        className="flex justify-center w-full lg:w-unset"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.idf.uk.net/"
      >
        <img
          loading="lazy"
          width="54"
          height="32"
          className="h-8 mb-0 mb-4 lg:mb-0"
          alt="Independent Doctors Federation"
          src={independentDoctors}
        />
      </a>
      <a
        className="flex justify-center w-full lg:w-unset"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.cmac.world/about/cmac-specialist-advisory-board/"
      >
        <img
          loading="lazy"
          width="87"
          height="32"
          className="h-8 mb-4 lg:mb-0"
          alt="CMAC Specialist Advisory Board"
          src={cmac}
        />
      </a>
      <a
        className="flex justify-center w-full lg:w-unset"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.pem.cam.ac.uk/international-programmes/pembroke-king%E2%80%99s-summer-programme/pembroke-circle/"
      >
        <img
          loading="lazy"
          width="34"
          height="32"
          className="h-8 lg:ml-5 lg:mb-0"
          alt="Pembroke College Circle"
          src={pembrokeCollege}
        />
      </a>{" "}
    </div>
  </div>
)
